<template>
	<div class="dashboard-container">
		<el-card v-if="isShow">
			<el-row >
				<el-col :span="6">
					<span  class="text-primary text-pad-right">日期  </span>
					<el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期"
                                    end-placeholder="结束日期"
					 clearable value-format="yyyy-MM-dd" size="mini" @change="changeTime" />
				</el-col>
				<el-col  :span="6">
					<span  class="text-primary text-pad-right">课程名称  </span>
					<el-input size="mini" v-model="searchVal" style="width: 200px" placeholder="请输入内容" prefix-icon="el-icon-search" @input="changeCondition"></el-input>
				</el-col>
			</el-row>
		</el-card>
		<el-card style="margin-top: 20px;" v-if="isShow">
			<el-row>
				<vxe-toolbar>
					<template v-slot:buttons>
						<el-row type="flex">
							<el-col :span="1">
								<vxe-button @click="shuaxin">刷新</vxe-button>
							</el-col>
						</el-row>
					</template>
				</vxe-toolbar>
				<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="560" row-id="ID" :sort-config="{remote: true}" :filter-config="{remote: true}"
				 :checkbox-config="{reserve: true}" :data="table.list" :loading="loading">
					<vxe-table-column type="checkbox" width="60" fixed="left" />
					<vxe-table-column type="seq" width="60" />
					<vxe-table-column title="课程名称" field="courseName" />
					<vxe-table-column title="课程类型" field="trainTypeName" />
					<vxe-table-column title="生效时间" field="startTime" />
					<vxe-table-column title="失效时间" field="endTime" />
					<vxe-table-column title="课程时长" field="courseDurationFormat" />
					<vxe-table-column field="" title="操作">
						<template v-slot="{ row, rowIndex }">
							<el-dropdown size="small" type="primary">
								<el-button size="small" type="primary">
									操作<i class="el-icon-arrow-down el-icon--right" />
								</el-button>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item icon="el-icon-edit" @click.native="openDialog(row)">查看</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</vxe-table-column>
				</vxe-table>
				<div style="height: 20px" />
				<el-pagination background :current-page="table.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]"
				 :page-size="table.pageSize" layout="total, ->, sizes, prev, pager, next, jumper" :total="table.total" @size-change="handleSizeChange"
				 @current-change="handleCurrentChange" />
			</el-row>
		</el-card>
		<el-row v-if="!isShow">
		  <el-col :span="22"><vxe-button @click="isShow = true">返回</vxe-button></el-col>
		  <el-col :span="2"><vxe-button @click="defenseExport">导出</vxe-button></el-col>
		</el-row>
		<el-card class="infoBox" v-if="!isShow">
			<div class="infoTitle">{{info.courseName}}</div>
			<div class="pt-2">
				<span class="fb-22">课程简介：</span>
				{{info.courseIntroduce}}
			</div>
			<div class="flex-row-box pt-3">
				<div class="fb-22">培训课件：</div>
				<div class="infoVideo">
					<video id="playVideos" controls="controls" :src="infoVideoUrl"></video>
				</div>
			</div>
			<div class="flex-row-box flex-justify-center">
				<div class="infoImg" v-for="(item,index) in info.courseVideoDetailsVos" :key="index"><img @click="imgClick(index)"
					 :src="$fileUrl + item.coverUrl"></div>
			</div>
			<div class="fb-22">考试题：</div>
			<div class="pt-1 pb-1" v-for="(item,index) in videoTopicDetailsVos" :key="index">
				<div style="font-weight: bold;" class="pt-2">{{index + 1}},{{item.topicTitle}}</div>
				<div style="padding-left: 20px;" class="flex-row-box flex-cont-center flex-wrap">
					<div class="pl-1 pt-2" v-for="(v,i) in item.topicOptionsVos" :key="i">{{v.options}}：{{v.remarks}}</div>
				</div>
				<div  style="padding-left: 20px;" class="col-4b97e3 pl-1 pt-2">解析:{{item.topicParsing}}</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		bytesToFile
	} from '@/utils/handleByte'
	import {
		rowStyle,
		headerStyle
	} from '@/utils/tableStyleJs'
	import {
		courseSelf,
		courseDetailsId
	} from '@/api'
	export default {
		name: 'Index',
		data() {
			return {
				loading: false,
				table: {
					list: [],
					total: 0,
					currentPage: 1,
					pageSize: 10,
					sortName: '',
					sortBy: ''
				},
				company: [],
				loginInfo: {},
				searchVal: '',
				start: '',
				end: '',
				date: [],
				isShow: true,
				info: {
					courseName: '',
					courseIntroduce: '',
					courseVideoDetailsVos: [],
				},
				infoVideoUrl: '',
				videoTopicDetailsVos: [],
				nowId: ''
			}
		},
		created() {
			this.getList()
		},
		methods: {
			rowStyle,
			headerStyle,
			changeCondition() {
				this.table.currentPage = 1
				this.getList()
				this.$refs.table.clearCheckboxRow()
			},
			shuaxin() {
				this.table.currentPage = 1;
				this.start = '';
				this.end = '';
				this.searchVal = '';
				this.date = [];
				this.getList()
				this.$refs.table.clearCheckboxRow()
			},
			getList() {
				this.loading = true
				this.$axiosReq(courseSelf, null, {
					courseName: this.searchVal,
					startDate: this.start,
					endDate: this.end,
					currentPage: this.table.currentPage,
					pageSize: this.table.pageSize
				}, 'get').then(res => {
					this.table.list = res.data.content
					this.table.total = Number(Number(res.data.totalElements))
				}).finally(e => {
					this.loading = false
				})
			},
			handleSizeChange(pageSize) {
				this.table.pageSize = pageSize
				this.table.currentPage = 1
				this.getList()
			},
			handleCurrentChange(currentPage) {
				this.table.currentPage = currentPage
				this.getList()
			},
			changeTime(v) {
				this.table.currentPage = 1
				if (v === null) {
					this.start = ''
					this.end = ''
				} else {
					this.start = v[0]
					this.end = v[1]
				}
				this.getList()
			},
			openDialog(row) {
				this.loading = true
				this.nowId = row.id;
				this.$axiosReq(courseDetailsId + row.id, null, null, 'get').then(res => {
					var dataInfo = res.data.courseVideoDetailsVos;
					this.info = res.data;
					if (dataInfo && dataInfo.length > 0) {
						this.infoVideoUrl = res.data.courseVideoDetailsVos[0].videoUrl;
						if (dataInfo[0].videoTopicDetailsVos && dataInfo[0].videoTopicDetailsVos.length > 0) {
							this.videoTopicDetailsVos = dataInfo[0].videoTopicDetailsVos;
						}
					}
					this.isShow = false
				}).finally(e => {
					this.loading = false
				})
			},
			defenseExport() {
				const loading = this.$loading({
					lock: true,
					text: '正在导出！',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				})
				this.$axiosReq('/course/server/escort/web/course/export/' + this.nowId, null, null, 'get').then(res => {
					bytesToFile(res.data.bytes, res.data.fileName)
				}).finally(() => {
					loading.close()
				})
			},
			imgClick(i) {
				var vdo = document.getElementById("playVideos")
				this.infoVideoUrl = this.info.courseVideoDetailsVos[i].videoUrl;
				this.videoTopicDetailsVos = this.info.courseVideoDetailsVos[i].videoTopicDetailsVos;
				vdo.src = this.infoVideoUrl;
				vdo.play();
			}
		}
	}
</script>

<style scoped>
	.searchTitle {
		width: 150px;
		text-align: center;
		line-height: 35px;
		color: #409EFF;
		font-weight: bold;
		font-size: 18px;
	}

	.infoBox {
		margin-top: 20px;
		height: calc(100vh - 200px);
		overflow-y: scroll;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
		font-size: 18px;
	}

	.infoBox::-webkit-scrollbar {
		display: none;
	}

	.infoTitle {
		text-align: center;
		font-size: 25px;
		font-weight: bold;
	}

	.fb-22 {
		font-weight: bold;
		font-size: 22px
	}

	.infoVideo {
		width: 800px;
		margin: 0 auto;
	}

	.infoVideo video {
		width: 100%;
	}

	.infoImg {
		width: 230px;
		margin: 10px;
	}

	.infoImg img {
		width: 100%;
	}

	.col-4b97e3 {
		color: #4b97e3;
	}
</style>
